.grid_1_50 {

    width: 45%;
    float: left;
  }

  .grid_2_25 {

    width: 30%;
    float: left;
  }
  .grid_3_25 {

    width: 25%;
    float: left;
    margin-bottom: 190px;
  }
  .inner_25{
    padding-left: 10px;
    margin-bottom: 83px;

  }
  .course_image{
    border-color: gray;
    height: 300px;
    background-color: #434343 !important;
    border-width: 1px;
    opacity: 1;
    border-style: solid double solid double;

  }
  .course_nodes{
    border-color: gray;
    padding: 5px;
    background-color: #434343 !important;
    border-width: 1px;
    opacity: .66;
    border-style: solid double solid double;

  }
  .prelude2{
    font-size: 20px;
    font-weight: 600;
    color: #313131;
text-align: left;
    opacity: .80;
 
  }
  .title_course2{
    text-align: left;
    font-size: 40px;
    font-weight: 800;
    color: #000;
    opacity: .84;
    font-size: 29px;
    margin-left: 0px;
    text-align: left;
  }
  .course_element{
    color: #000;
    text-align: left !important;
    opacity: .66;

  }
  .course_element_title{
    color: #eee;
    text-align: left;
    opacity: .94;

    font-weight: 800;
    font-size: 27px;
  }
  .course_element_description{
    color: #fafafa;
    text-align: left;
    opacity: .87;
    padding: 18px;
    font-size: 19px;
    padding-bottom: 39px;

  }
  #playImg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;

    display: block;
    margin: auto; }
    #frame {
      position: relative;
  
      width: 300px;
      height: 200px;
  
      cursor: pointer;
      background-position: center;
      background-size: cover; }

      .learn_list{
        list-style-type: none;
        color: azure;
        font-size: 19px;
        text-align: left;
        list-style-image: url('./img/check-mark_16.png');

      }
      .grid_video{
        display: none;
      }
     

      ul {
      }
   
      
      #course_container{

      }
      .nft_access{
        margin-top: 33px;
        color: aqua;
        background-color: #2196F3;
        padding: 15px;
        width: 270px;
        border-radius: 5px;
        transition: .633s ease;

      }
      a.buy{
        all:unset;
      }
      a.buy:hover{
        cursor: pointer;
      }
      .buy_udemy_btn{
        margin-top: 10px;
        color: #f5f5f5;
        z-index: 100000 !important;
        background-color: #ba2cdd;
        padding: 15px;
        width: 270px;
        border-radius: 5px;
        transition: .633s ease;

      }
      .buy_udemy_btn{
        cursor: pointer;
      }
      .buy_skillshare_btn{
        all: unset;
        transition: .633s ease;

        margin-top: 10px;
        color: #f5f5f5;
        background-color: #232224;
        padding: 15px;
        width: 270px;
        border-radius: 5px;
      }

      .buy_skillshare_btn:hover, .buy_udemy_btn:hover, .nft_access:hover{
        transition: .33s ease;
        background-color: #56525a;
      }
      .nft_access:hover{
        cursor: pointer !important;
      }
      @media screen and (max-width: 768px){
        
        .grid_2_25 {
          width: 100%;
          float: center;
          margin: 11px;
          align-content: center;
          padding-top: 33px;
          padding-bottom: 133px;
          background-color: linen;
          padding-bottom: 40px;
          margin-bottom: 33px;
          height: 480px;

        }
        #frame {
          align-content: center;
          width: 80%;
          height: 233px;
          margin: auto;
          padding-bottom: 20px;
          margin-bottom: 3px;
        }
        .nft_access{
          margin-top: 70px;
          width: 90%;
        }
       
        .buy_skillshare_btn{
          width: 90%;

        }
        .buy_udemy_btn{
          width: 90%;

        }
        .grid_1_50 {
          width: 100%;
          float: left;
        }
      
        .grid_3_25 {
          width: 100%;
          float: left;

        }
      } 
      #buyNFTs{
        width: 55%;
        background-color: #282c34;
        color: coral;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        transition: .66s ease-in-out;
        margin-bottom: 90px;

      }
      #buyNFTs:hover{
        background-color: #2196F3;
        transition: .66s ease-in-out;
      }
      .afterDiv{
        height: 200%;
      }
      #goToCourse{
        width: 55%;
        background-color: #282c34;
        color: aliceblue;
        padding-top: 10px;
        padding-bottom: 10px;
      
        cursor: pointer;
      }
      #goToCourse:disabled{
        opacity: .26;
        cursor: not-allowed;
      }
      @media only screen and (max-width: 768px) {
        #buyNFTs{
          width: 90%;
        }
        #goToCourse{
          width: 90%;

        }
      }