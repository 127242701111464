.grid-container-3 {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #FAFAFA;
    padding: 10px;
  }
  .grid-container-2 {
    display: grid;
    grid-template-columns: auto auto ;
    background-color: #FAFAFA;
    padding: 10px;
  }
  .grid-container-1 {
    display: grid;
    grid-template-columns: auto;
    background-color: #FAFAFA !important;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    /*border: 1px solid rgba(0, 0, 0, 0.8);*/
    padding: 20px;
    font-size: 20px;
    color: black;
    text-align: center;
  }
  #container{
    background-color: #333;
    transition: .66s ease-out;
  }
  #content{
    background-color: #fff !important;
    height: 90%;
    padding-bottom: 190px !important;
    margin-bottom: 190px !important;


  }
  @media only screen and (max-width: 860px){
    .grid-container-3 {
        display: grid;
        grid-template-columns: auto auto;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-2 {
        display: grid;
        grid-template-columns: auto auto;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-1 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #2196F3;
        padding: 10px;
      }
  }
  @media only screen and (max-width: 728px){
    .grid-container-3 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-2 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
      .grid-container-1 {
        display: grid;
        grid-template-columns: auto ;
        background-color: #FAFAFA;
        padding: 10px;
      }
  }
