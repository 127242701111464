.footer {
    position: fixed;
  height: 60px;
    left: 0;
    bottom: 0;
    opacity: .77;
    width: 100%;
    background-color: rgb(253, 253, 253);
    color: #232323;
    text-align: center;
  }