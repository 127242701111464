.header_move2{
    opacity: 1;
    transition: .6s ease-in;
    margin-left: 270px !important;
    background-color: #161616 !important;

}
.show_resource{
display: block;
transition: .66s ease-in-out;
}
.hide_resource{
    display: none;
    transition: .66s ease-in-out;
    
}
.title_url{
    color: #0fbefd  ;
}
#resources{
    text-align: left;
    padding-left: 25px;
}
.player-wrapper{
    margin-top: -0px;
    transition: .26s ease-in;

    height: 300px;
    width: 444px;
}
.player-wrapper-large{
    margin: auto;
    transition: .26s ease-in;
    margin-top: -5px;
    margin-left: 0;
align-content: left;
width: 60%;
height: 60%;
}
.main_visible2{
    opacity: 1;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #181717 !important;
    height: 900px;
    position: absolute;
    margin: 0;
    width: 100%;
    }
    .full_video{
        width: 100% !important;
    }

    .img_plus{
height: 24px;
width: 24px;
filter: invert(.666);
margin-left: 0px;
left: 18px;
position: relative;
    }
    .nested2{
        all: unset;
        margin: auto;
        top: 0px;
        position: relative;
        pointer-events: none;

    }
    .nested3 {
        all: unset;
        margin: auto;
        top: -25px;
        left: 20px;
        position: relative;
        pointer-events: none;


    }
@media only screen and (max-width: 700px) {

    .player-wrapper-large{
width: 100%;
    }
}

.noneD{
    display: none;
    
}
.blockD{
display: block;
}
.full_btn{
    all: unset;
}
#sections{
    overflow-y: visible !important;
    overflow-x: hidden !important;
    height: 69% !important;
    
}
@media only screen and (max-width:768px) {
    #sections{
        height: 55% !important;

    }
    
}
.isInactive{
    background-color: #0fbefd !important;
    transition: .66s ease-in;
}
.isActive{
    background-color: #0af816 !important;
    margin-top: auto !important;
    padding: 10px !important;
    margin-left: -19px !important;
    transition: .666s ease-in;

}
