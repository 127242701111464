.headline_courses{
    font-size: 27px;
    color: #676767;
}
.preview_course{
  background-color:yellowgreen;
  padding: 12px;
  width: 190px;
  border-radius: 12px;
  transition: .666s ease-in;
  padding-bottom: 10px;
  margin-bottom: 10px;

}
.preview_course:hover{
  background-color:rgb(69, 72, 75);
  padding: 12px;
  width: 190px;
  border-radius: 12px;
  transition: .666s ease-in;
  cursor: pointer;
}
.title_course{
    color: rgb(17, 18, 18);
    width: 313px;
    font-size: 18px;
    text-align: left;
    position: relative;
    margin: auto;
}
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 333px;
    cursor: pointer;
    border-radius: 12px;
  }
  .time{
    padding-left:160px;
    top: -20px;
    font-weight: 700;
    
      }
  @media only screen and (max-width: 666px)  {
    .card{
      width: 100%;
    }
    .title_course{
      width: 100%;
    }
    .time {
      padding-left: 120px;
    }
    
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }
.card a{
  all: unset;
}
  .card img{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

  }

 
  .topContainer{
  }

 
