.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#wallet{
  width: 200px;
  margin-left: 40px;
  top: 20px;
  position: relative;
  }
  #instruction{
    top: 29px !important;
    position: relative;
    color: #282c34;
  }
@media only screen and (max-width: 768px){
  #wallet{
    width: 200px;
    margin-left: 40px;
    top: 60px;
    position: relative;
  
  } 
  #instruction{
    top: 69px !important;
    position: relative;
    color: #282c34;
  }
  #brand_name{
    display: none !important;
  }
  #img_logo{
    margin-left: -25px !important;
  }
  .button{
    display: none !important;
  }
}
#nfts{
  color: #111;
}
.wallet-adapter-button{
  margin-left: -30px !important;
}
.wallet-adapter-modal-list li {
  width: 87% !important;
  margin-left: 30px;
}
@media only screen and (max-width: 768px) {
  #nfts{
    margin-top: 50px !important;
    margin-right: 20px !important;
    position: relative;
    color: #000;
    margin-left: 10px;
    width: 100%;
    
    
  }
  .grid_3_25{
  }
  
}


